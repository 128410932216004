import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import HomePageCardComponent from '../../HomePageCard/HomePageCardComponent';
import YouTubeCard from '../../YouTubeComponent/YouTubeCard';
import "./FeatureDetailsComponent.css";
import { useSelector } from 'react-redux';

const FeatureDetailsComponent = memo(function FeatureDetailsComponent(props) {
    const {
        dataArr = null,
        componentName = "",
        visibleFeatureDetailsBusinessPlan = "",
        HeadingTag = "h2",
        isShowMoreFunc = {
            isMobile: false
        },
        logoPath=""
    } = props;
    const [isShowMoreActive, setIsShowMoreActive] = useState(false)
    const screen = useSelector(state => state.common.screen);
    let renderedArray = [];
    return (
        <div className={`feature-details-component ${visibleFeatureDetailsBusinessPlan}`} id={componentName}>
            {logoPath && <div className='our-features-logo-wrapper'>
                <img src={logoPath} height={50} width={50} alt='feature_details_icon' />
            </div>}
            {
                dataArr && dataArr.length && dataArr.map((obj, index) => {
                    const btnBgCssClass = obj.button && obj.button.btnBgType === "gray" ? "gray-bg-btn" : "yellow-bg-btn";
                    const btnSizeCssClass = obj.button && obj.button.btnSize === "small" ? "small-size-btn" : "med-size-btn";
                    renderedArray = isShowMoreFunc?.isMobile && screen?.width < 576 ? isShowMoreActive ? obj?.paragraphs : obj?.paragraphs.slice(0, 1) : obj?.paragraphs
                    return (
                        <React.Fragment key={`${componentName}_heading_para_section_${index}`}>
                            {obj.title && obj.title !== "" && <h2 className="title">{obj.title}</h2>}
                            {obj?.header && <HeadingTag className="header-text" dangerouslySetInnerHTML={{ __html: obj.header }} />}
                            {renderedArray?.length && renderedArray.map((value, index) =>
                                <p className={`paragraph-text ${obj.cssClass}`} key={`${componentName}_paragraph_${index}`} dangerouslySetInnerHTML={{ __html: value }} />)}
                            {obj?.imageObj?.imageUrl ? <picture>
                                <source srcSet={obj.imageObj.webpImageUrl} />
                                <img className={obj.imageObj.className}  src={obj.imageObj.imageUrl} alt={obj.imageObj.alt} width={obj.imageObj.width} height={obj.imageObj.height} />
                            </picture> : ""}
                            {obj.linkNote && (obj.linkNote?.url ?
                                <Link to={`${obj.linkNote?.url}`}><span
                                    style={obj.linkNote?.style}>{obj.linkNote?.text}</span></Link> :
                                <span onClick={obj?.linkNote?.onClick} style={obj?.linkNote?.style} className={obj?.linkNote?.cssClass}>{obj?.linkNote?.text}</span>)}
                            {obj?.cardDataList?.length > 0 ? <HomePageCardComponent
                                cardDataList={obj.cardDataList}
                                cardTextClassStr="pom-font-22-16-16"
                            /> : ""}
                            {obj.button ? <button className={`feature-details-btn ${btnBgCssClass} ${btnSizeCssClass}`}
                                onClick={obj.button.onClick} pomandae2e={obj.header}>{obj.button.text}</button> : 
                                obj.styledButton ? <div className='outline-border-yellow-bg-btn'><button className={`feature-details-btn ${btnBgCssClass} ${btnSizeCssClass}`} onClick={obj.styledButton.onClick}>{obj.styledButton.text}</button></div> : null}
                            {obj.youtubeCardData && <YouTubeCard youtubeCardData={obj.youtubeCardData} />}
                            {isShowMoreFunc.isMobile && screen.width < 576 && 
                                <span className='our-feature-show-hide-btn' onClick={() => setIsShowMoreActive(!isShowMoreActive)}>{isShowMoreActive ? "Hide" : "Show More"}</span>}
                        </React.Fragment>
                    )
                })
            }
        </div>
    );
});

export default FeatureDetailsComponent;